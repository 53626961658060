document.addEventListener("DOMContentLoaded", (function (e) {
  ! function () {
    const e = (new Date).getFullYear();
    document.querySelector(".year").textContent = e
  }()

  const popup = document.querySelector('.popup');
  const form = document.querySelector('#form');
  const popupClose = document.querySelector('.popup__close');
  const submit = document.querySelector('.submit');

  popupClose.addEventListener('click', (e) => {
    popup.classList.remove('shown');
  });

  form.addEventListener('submit', (e) => {
    e.preventDefault();
    popup.classList.add('shown');
  });

}));
